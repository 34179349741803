import { useRouter } from "next/router";
import pathChecking from "../../utils/pathChecking";
import Testimonial_carousel from "../carousel/testimonial_carousel";

const Testimonial = ({ bg_jacerta }) => {
  const route = useRouter();

  return (
    <div>
      {/* <!-- Testimonials --> */}
      <section
        className={
          bg_jacerta
            ? "testimonial-section bg-light-base py-24 dark:bg-jacarta-800"
            : `testimonial-section ${
                pathChecking(route.asPath, "/home/home_10")
                  ? "bg-white"
                  : "bg-light-base"
              } py-24 dark:bg-jacarta-900`
        }
      >
        <div className="relative overflow-hidden w-100">
          <div className="mx-auto mb-12 text-center">
            <h2 className="mb-6 text-center font-display text-3xl text-jacarta-700 dark:text-white">
              Satisfied Customers
            </h2>
            <div className="text-lg dark:text-jacarta-300">Customers those who already tried our services.</div>
          </div>

        </div>
        <div className="pl-8 relative overflow-hidden xl:pr-[20vw]" style={{ width: '100%'}}>
          <Testimonial_carousel />
        </div>
      </section>
      {/* <!-- end testimonials --> */}
    </div>
  );
};

export default Testimonial;
