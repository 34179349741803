import Image from 'next/image';
import CreditCard from '../../../public/images/home/home-card.png';
import Link from 'next/link';
import React from 'react';

const HomeCard = () => {
  return (
    <section className='relative py-20'>
      <picture className="pointer-events-none absolute inset-0 -z-10">
        <Image
          src="/images/gradient_dark.jpeg"
          alt="gradient"
          className="h-full w-full"
          layout="fill"
        />
      </picture>
      <div className="container">
        <div className="h-full items-center gap-4 flex flex-col lg:grid lg:grid-cols-12">
          <div className="flex h-full flex-col items-center justify-center py-10 lg:col-span-5 lg:items-start lg:py-20 order-last lg:order-first">
            <h2 className='text-jacarta-700 font-display text-center lg:text-left font-bold text-4xl mb-8 dark:text-white'>
              Crypto Card by Alchemis
            </h2>
            <div className='text-white mb-10 text-center lg:text-left'>
              Earn up to 1% cashback every time you spend crypto.
            </div>
            <a
              href="/product/crypto-prepare-card"
              className="rounded-full bg-white py-3 px-8 text-center font-semibold text-primary transition-all hover:bg-primary hover:text-white"
            >
              Learn More
            </a>
          </div>

          {/* <!-- Image --> */}
          <div className="col-span-7">
            <div className="relative text-center order-first lg:order-last">
              <Image
                src={CreditCard.src}
                alt='card'
                height={278}
                width={500}
                className='mx-auto mt-8 w-[100%]'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeCard;
