import HelpBlock from "./HelpBlock";

const index = () => {
  return (
    <section className="relative bg-light-base py-24 dark:bg-jacarta-900">
      <div className="container">
        <div className="items-center justify-between lg:flex lg:space-x-24">
          <div className="lg:w-[45%]">
            <h2 className="mb-6 font-display text-3xl font-medium text-jacarta-700 dark:text-white">
              About Alchemis
            </h2>
            <p className="mb-6 text-lg dark:text-jacarta-300">
              Alchemis is an innovative security firm focusing on block-chain ecosystem.
            </p>
            <p className="dark:text-jacarta-300">
              We started from a team with over ten years of cyber security experience to become a global leading block-chain security experts
            </p>
          </div>
          <div className="lg:w-[55%]">
            <HelpBlock />
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
