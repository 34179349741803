const Partner = () => {
  const partnerItem = [
    {
      name: 'Ethereum',
      file: "ethereum",
    },
    {
      name: 'Tron',
      file: "tron",
    },
    {
      name: 'BSC',
      file: "bsc",
    },
    {
      name: 'Arbitrum',
      file: "arbitrum",
    },
    {
      name: 'Polygon',
      file: "polygon",
    },
    {
      name: 'Optimism',
      file: "optimism",
    },
    {
      name: 'Avalanche',
      file: "avalanche",
    },
    {
      name: 'Solana',
      file: "solana",
    },
    {
      name: 'Bitcoin',
      file: "bitcoin",
    },
    {
      name: 'Ethereum',
      file: "ethereum",
    },
    {
      name: 'Tron',
      file: "tron",
    },
    {
      name: 'BSC',
      file: "bsc",
    },
    {
      name: 'Arbitrum',
      file: "arbitrum",
    },
    {
      name: 'Polygon',
      file: "polygon",
    },
    {
      name: 'Optimism',
      file: "optimism",
    },
    {
      name: 'Avalanche',
      file: "avalanche",
    },
    {
      name: 'Solana',
      file: "solana",
    },
    {
      name: 'Bitcoin',
      file: "bitcoin",
    },
  ];
  return (
    <div className={"space-x-8 flex"}>
      {partnerItem.map((item, i) => (
        <div
          style={{width: '252px', height: '92px', animation: 'none'}}
          className="flex flex-shrink-0 items-center justify-center rounded-2.5xl p-6"
          key={i}
        >
          <img src={`/images/partner/${item.file}.svg`} alt="partner item" />
          <span className={'pl-2 font-bold text-xl'}>{item.name}</span>
        </div>
      ))}
    </div>
  );
};

export default Partner;
