import FancyBlock from '../components/crypto/FancyBlock';
import HomeCard from '../components/home-card';
import NeedHelpBlock from '../components/crypto/nee-help-block';
import Hero_10 from '../components/hero/hero_10';
import Testimonial from '../components/testimonial/Testimonial';
import TrustedPartner from '../components/dao/TrustedPartner';
import { NextSeo } from 'next-seo';
import CallToActions from '@apps/components/ico-landing/CallToActions';
import React from 'react';
import FeaturesCard from '../components/crypto/features-card';

const Home = () => {
  const metaTitle = 'Alchemis - Exploring new horizon of web3 technology';
  const metaDesc = `We believe in working collaboratively with our clients to create innovative solutions that unlock the full potential of Web3 to drive business growth.`;
  const metaImg = `/images/meta/home.png`;
  const featuresList = [
    {
      id: 1,
      icon: '/images/crypto-app/w1.svg',
      title: 'Blockchain Development',
      text: `Blockchain development services: Empowering businesses with secure and decentralized solutions.`
    },
    {
      id: 2,
      icon: '/images/crypto-app/w2.svg',
      title: 'Smart Contracts Development',
      text: `Smart contract development for ICOs, DeFi, and dApps. Security audits and optimization strategies for reliable, efficient contracts.`
    },
    {
      id: 3,
      icon: '/images/crypto-app/w3.svg',
      title: 'Decentralized Application (dApps) Development',
      text: `Specialize in dApp development, including gaming, blockchain-based apps, wallet development, and DeFi applications.`
    },
    {
      id: 4,
      icon: '/images/crypto-app/w4.svg',
      title: 'Blockchain Consultation',
      text: `Expert blockchain consultation for strategic advice, integration, and utilization.`
    },
    {
      id: 5,
      icon: '/images/crypto-app/w5.svg',
      title: 'Tokenization Services',
      text: `Tokenization services: design, governance, launch, and exchange listing.`
    },
    {
      id: 6,
      icon: '/images/crypto-app/w6.svg',
      title: 'Non-Fungible Tokens (NFT) Solutions',
      text: `Complete NFT solutions: asset tokenization, minting, marketplaces, ticketing, staking, metaverse.`
    }
  ];
  const content = {
    title: "Exploring new horizon of web3 technology",
    descriptions: `We believe in working collaboratively with our clients to create innovative solutions that unlock the full potential of Web3 to drive business growth.`,
    btnText: "Contact Us",
    btnUrl: '/contact',
    headImage: '/images/home/home-header.png',
    animationImage: '/images/home/header-side.png',
    backgroundImage: '/images/gradient_dark.jpeg',
  };

  return (
    <main>
      <NextSeo
        title={metaTitle}
        description={metaDesc}
        openGraph={{
          title: `${metaTitle}`,
          description: `${metaDesc}`,
          images: [
            {
              url: `${metaImg}`
            }
          ]
        }}
      />
      <Hero_10 content={content} />
      <TrustedPartner />
      <FancyBlock />
      <HomeCard />
      <FeaturesCard
        title={'Alchemis Lab'}
        subTitle={'Your account is secured by private facial biometrics and industry-leading encryption to keep you safe from account takeover attacks and phishing. Discover total control and true peace of mind.'}
        featureList={featuresList}
        buttonText={'View Alchemis Lab'}
        buttonUrl={'/labs'}
      />
      {/* <Testimonial /> */}
      <NeedHelpBlock />
      <CallToActions
        title={"Interested in creating a Web3 project?"}
        background={"/images/cta.png"}
        description={"Contact us today to see how our Web3 solution will help you realise your full business potential!"}
        btnText={"Start Now"}
        link={"/contact"}
      />
    </main>
  );
};

export default Home;
